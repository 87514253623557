<template>
    <div>
        <div></div>
    </div>
</template>
<script>

export default {
    data() {
        return {
        }
    },
    mounted(){
    },
}
</script>

<style scoped lang="scss">
</style>